export default class PasswordResetCompletionRequestDTO {

    public resetPasswordCode: string;

    public password = "";

    public confirmPassword = "";

    constructor(resetPasswordCode: string) {
        this.resetPasswordCode = resetPasswordCode;

    }

}